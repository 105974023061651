import React, { Component } from 'react';
import {Row, Col, Container, Card} from 'react-bootstrap';

class Portfolio extends Component{
    render(){
      return(
        <div id="portfolioDiv">
          <hr/>
          <Container>
            <Row> 
             <Col md={4} xs={12}>
              <Card className="text-center">
                <Card.Img variant="top" src="img/Rise.jpg" alt="Vending Machine SpringMVC" ></Card.Img>
                <Card.Header><a href="https://rise-as-one-website.firebaseapp.com/home" >Rise As One Web Application</a></Card.Header>
                <Card.Body>
                  <Card.Text>
                    This project was produced for a client establishing their brand in diversity training
                  </Card.Text>
                  <Card.Footer className="text-muted">
                    Technologies Used:
                    <ul style={{fontSize:13}}>
                      <li>Angular 9</li>
                      <li>Bootstrap 4</li>
                      <li>Google Firebase</li>
                      <li>Google Analytics</li>
                      <li>Firebase Functions</li>
                      <li>Firebase Hosting</li>
                    </ul>
                  </Card.Footer>
                </Card.Body>
              </Card>
             </Col>
             <Col md={4} xs={12}>
             <Card className="text-center">
                <Card.Img variant="top" src="img/VendingMachineSpringMVC.png" alt="Vending Machine SpringMVC" ></Card.Img>

                <Card.Header><a href="https://spring-vending-machine.herokuapp.com/">Vending Machine</a></Card.Header>
                <Card.Body>
                  <Card.Text>
                  This project explored the use of HTTP calls requiring the customer to click a button to add money and select an item to purchase. On the backend, a Spring container used controller servlets to produce responses.
                  </Card.Text>
                  <Card.Footer className="text-muted">
                    Technologies Used:
                    <ul style={{fontSize:13}}>
                      <li>JQuery</li>
                      <li>Bootstrap 3</li>
                      <li>Java</li>
                      <li>Spring</li>
                      <li>Tomcat</li>
                      <li>Maven</li>
                      <li>Heroku</li>
                    </ul>
                  </Card.Footer>
                </Card.Body>
              </Card>
             </Col>
             <Col md={4} xs={12}>
             <Card className="text-center">
                <Card.Img variant="top" src="img/mini_case.png" alt="Vending Machine SpringMVC" ></Card.Img>

                <Card.Header><a href="https://minicasemanagementsystem20200721110743.azurewebsites.net/">Mini Case Management System</a></Card.Header>
                <Card.Body>
                  <Card.Text>
                    Example project build to show profeciency with ASP.NET Framework and Windows based tools
                  </Card.Text>
                  <Card.Footer className="text-muted">
                    Technologies Used:
                    <ul style={{fontSize:13}}>
                      <li>Azure App Services</li>
                      <li>Bootstrap</li>
                      <li>MySQL</li>
                      <li>Entity Framework 6</li>
                      <li>ASP.NET</li>
                      <li>C#</li>
                      <li>IIS</li>
                    </ul>
                  </Card.Footer>
                </Card.Body>
              </Card>
             </Col>
            </Row>
          </Container>
        </div>
      );
    };
  }

  export default Portfolio;