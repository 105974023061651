import React, { Component } from "react";
import { Row, Col, Container } from "react-bootstrap";

class AboutMe extends Component {
  render() {
    return (
      <div id="aboutMeSection">
        <Container>
          <Row>
            <Col md={3}>
              <img
                src="img/myphoto.jpg"
                align="left"
                className="rounded-circle thick-navy-border"
                width="200px"
                height="200px"
                alt="Professional Pic"
              />
            </Col>
            <Col md={9} xs={12} style={{ textAlign: "left" }}>
              <h1 className="aboutMeText">{"{Ciao - I'm Stephon}"}</h1>
              <hr />
              <div className="alert alert-custom">
                <h2 className="aboutMeText">I'm a Developer</h2>
                <hr />
                <p id="smallblurb" className="aboutTextBlurb">
                  I am a commited full-stack developer with a foundation
                  provided by The Software Guild. Consider this my showcase and
                  contact me for collaboration and work.
                </p>
              </div>
            </Col>
          </Row>
          <Row style={{ paddingBottom: 20 }}>
            <Col md={3} xs={12} style={{ paddingBottom: 20 }}>
              <h4>Bio</h4>
              <p style={{ fontSize: 20 }}>
                Full-Stack Developer providing eloquent web-based solutions.
                These solutions currently include Java/C# on the back-end,
                MySQL/SQL Server as the database solution, and React.JS as a
                front-end framework.
              </p>
            </Col>
            <Col md={9} xs={12}>
              <div id="textDiv">
                <div className="aboutTextBlurb">
                  <hr />
                  <h2>Skills:</h2>
                  <Row id="skillsText" style={{ paddingBottom: 20 }}>
                    <Col md={4} xs={12}>
                      <h4>Languages:</h4>
                      <p>C#, Java, JavaScript, HTML, CSS, SQL</p>
                    </Col>
                    <Col md={4} xs={12}>
                      <h4>Concepts:</h4>
                      <p>
                        OOP, N-Tier Design, MVC, MVVM, Full-Stack Development,
                        RESTful Web Services
                      </p>
                    </Col>
                    <Col md={4} xs={12}>
                      <h4>Frameworks:</h4>
                      <p>
                        ASP.NET MVC, Bootstrap, jQuery, Spring MVC, Framework 7,
                        Ajax, JSTL
                      </p>
                    </Col>
                  </Row>
                  <Row id="skillsText" style={{ paddingBottom: 20 }}>
                    <Col md={4} xs={12}>
                      <h4>Tools:</h4>
                      <p>
                        Git, GitHub, Canvas, Slack, JUnit, BitBucket, Draw.io,
                        MySQL, Apache Maven, Apache Tomcat, Spring JDBC
                      </p>
                    </Col>
                    <Col md={4} xs={12}>
                      <h4>IDEs:</h4>
                      <p>
                        NetBeans,Visual Studio, Visual Studio Code, IntelliJ
                        IDEA
                      </p>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default AboutMe;
