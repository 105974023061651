import React, { Component } from "react";
import { Row, Col, Container, Card, Button } from "react-bootstrap";
import { animateScroll as scroll } from "react-scroll";

export default class ContactMe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "",
      validated: false,
      email: "",
      name: "",
      number: "",
      message: "",
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.scrollToTop = this.scrollToTop.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  handleSubmit = (event) => {
    const formInfo = event.currentTarget;
    if (formInfo.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      this.setState({validated:true})
      return
    }
    this.setState({validated:false})

    let data = {
      email: this.state.email,
      name: this.state.name,
      number: this.state.number,
      message: this.state.message,
    };
    const xhr = new XMLHttpRequest();
    xhr.open("POST", "https://formspree.io/xyynleno");
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        this.setState({
          status: "SUCCESS",
          validated: false,
          email: "",
          name: "",
          number: "",
          message: "",
        });
      } else {
        this.setState({ status: "ERROR" });
      }
    };
    xhr.send(data);
  };

  scrollToTop() {
    scroll.scrollToTop();
  }

  render() {

    return (
      <div style={{ marginBottom: 50 }} id="contactMeDiv">
        <Container>
          <hr />
          <h2>Contact Me</h2>
          <Row>
            <Col lg={12}>
              <Card className="text-left">
                <Card.Header
                  style={{ color: "white", backgroundColor: "#0e1e25" }}
                >
                  Message Me
                </Card.Header>
                <Card.Body>
                <form
                  action="https://formspree.io/xyynleno"
                  method="POST"
                >
                  <div class="form-group">
                    <label for="email">Email address</label>
                    <input type="email" class="form-control" id="email" name="email" placeholder="name@example.com" required/>
                  </div>
                  <div class="form-group">
                    <label for="name">Name</label>
                    <input type="text" minlength="2" class="form-control" name = "name" id="name" placeholder="John Doe" required/>
                  </div>
                  <div class="form-group">
                    <label for="number">Phone Number</label>
                    <input type="tel" minlength="7"class="form-control" name ="number" id="number" placeholder="330-440-3322" required/>
                  </div>
                  <div class="form-group">
                    <label for="message">Message</label>
                    <textarea class="form-control" name = "message" id="message" rows="3" required></textarea>
                  </div>
                  <Button type="submit" variant="primary" size="lg" block>Submit</Button>
                </form>
                </Card.Body>
                <Card.Footer className="text-center">
                  <a href="mailto: stebrown2018@gmail.com">
                    <i className="fas fa-envelope" style={{ fontSize: 30 }} />
                  </a>
                  &nbsp; &nbsp;
                  <a
                    href="https://www.linkedin.com/in/stephon-brown-87779572/"
                    target="#"
                  >
                    <i className="fab fa-linkedin" style={{ fontSize: 30 }} />
                  </a>{" "}
                  &nbsp; &nbsp;
                  <a href="https://github.com/StephonBrown" target="#">
                    <i className="fab fa-github" style={{ fontSize: 30 }} />
                  </a>{" "}
                  &nbsp; &nbsp;
                  <a href="https://codepen.io/Tatermate09/" target="#">
                    <i className="fab fa-codepen" style={{ fontSize: 30 }} />
                  </a>{" "}
                  &nbsp; &nbsp;
                  <a
                    href="https://www.freecodecamp.org/stephonbrown"
                    target="#"
                  >
                    <i
                      className="fab fa-free-code-camp"
                      style={{ fontSize: 30 }}
                    />
                  </a>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col
              md={12}
              xs={12}
              style={{ paddingTop: 25, textAlign: "center" }}
            >
              <p>Return to Top</p>
              <a href="/" onClick={this.scrollToTop}>
                <i
                  id="returnArrow"
                  className="fas fa-arrow-circle-up blink"
                  style={{ textAlign: "center", fontSize: 30 }}
                ></i>
              </a>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
