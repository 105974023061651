import React, { Component } from 'react';
import {Nav, Navbar} from 'react-bootstrap';
import { Link} from 'react-scroll'

class NavbarComponent extends Component{
    render(){
      return(
            <Navbar id ="navBar" collapseOnSelect style={{marginBottom:20}} expand="lg" bg="#0e1e25" variant="dark">
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="ml-auto">
                    <Nav.Item style = {{textDecoration: "none", color:"white"}}><Link className= "nav-link" to="aboutMeSection" spy={true} smooth={true}>About Me</Link></Nav.Item>
                    <Nav.Item style = {{textDecoration: "none", color:"white"}}><Link className= "nav-link" to="portfolioDiv" spy={true} smooth={true}>Portfolio</Link></Nav.Item>
                    <Nav.Item style = {{textDecoration: "none", color:"white"}}><Link className= "nav-link" to="contactMeDiv" spy={true} smooth={true}>Contact Me</Link></Nav.Item>
                    <Nav.Item style = {{textDecoration: "none", color:"white"}}><a className= "nav-link" target="#" href="https://opensource.com/users/stephb">Writings</a></Nav.Item>
                </Nav>
            </Navbar.Collapse>
            </Navbar>
      );
    };
  }
  
  export default NavbarComponent