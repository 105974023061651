import React, { Component } from 'react';
import NavbarComponent from './Components/NavbarComponent';
import TopParticleCanvas from './Components/Canvas/TopParticleCanvas';
import BottomParticleCanvas from './Components/Canvas/BottomParticleCanvas';
import AboutMe from './Components/AboutMe';
import Portfolio from './Components/Portfolio';
import ContactMe from './Components/ContactMe';

import'./App.css';



class App extends Component {
  render() {
    return (
      <div id ="entirePage">
          <NavbarComponent/>
          <header id={"particleDiv"} className="padded-container">
            <h1 id="title">Welcome to Innovation<span className = "blink">_</span></h1> 
            <TopParticleCanvas/>
          </header>
            <AboutMe/>
            <Portfolio/>
            <ContactMe/>
            <div style={{marginBottom: 0, paddingBottom: 0}}>
              <footer  style={{marginTop: -5}}>
              Designed and Coded by Stephon Brown
            </footer>
              <BottomParticleCanvas/>
            </div>

      </div>
    );
  };
}











export default App;
